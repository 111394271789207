import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { themeModule } from '../@theme/theme.module';
import { LoaderComponent } from './Component/loader/loader.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { PaginatorComponent } from './Component/paginator/paginator.component';
import { MatNativeDateModule } from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';




@NgModule({
  declarations: [LoaderComponent,PaginatorComponent],
  imports: [
    CommonModule,
    themeModule,
    MatProgressSpinnerModule,
    FormsModule,
    ReactiveFormsModule,
    MatNativeDateModule,
    MatDatepickerModule,
    ],
exports:[LoaderComponent,PaginatorComponent]
})
export class CoreModule { }
