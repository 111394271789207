import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';


@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private userSessionName = 'citizens-currentUser';
  private userTokenSessionName = 'citizens-currentUserToken';
  private isLogin = new BehaviorSubject<boolean>(this.isLoginToken());
  currentIsLogin = this.isLogin.asObservable();

  constructor() {}

  public currentUser() {
    return this.getUser();
  }

  public get currentUserToken(): string {
    return this.getUserToken();
  }

  public get userLogedIn(): boolean {
    if (this.getUserToken() != '') {
      this.isLogin.next(true);
      return true;
    } else {
      this.isLogin.next(false);
      return false;
    }
  }

  login(loginToken: string) {
    const token = { token: loginToken };
    this.isLogin.next(true);
    localStorage.setItem(this.userTokenSessionName, JSON.stringify(token));
  }

  logout() {
    // remove user from local storage to log user out
    this.isLogin.next(false);
    localStorage.removeItem(this.userSessionName);
    localStorage.removeItem(this.userTokenSessionName);
  }

  private getUser() {
    const userStorage = JSON.parse(
      localStorage.getItem(this.userSessionName) as any
    );
    let userObj = null;
    if (userStorage !== null && userStorage !== undefined) {
      userObj = userStorage as unknown;
    }

    return userObj;
  }

  private getUserToken(): string {
    let token = '';
    const userStorage = JSON.parse(
      localStorage.getItem(this.userTokenSessionName) as any
    );
    let userObj = null;
    if (userStorage !== null && userStorage !== undefined) {
      userObj = (userStorage as unknown) as any;
      token = userObj.token;
    }

    return token;
  }

  private isLoginToken(): boolean {
    let AlreadyLogin = false;
    const userStorage = JSON.parse(
      localStorage.getItem(this.userTokenSessionName) as any
    );
    if (userStorage !== null && userStorage !== undefined) {
      AlreadyLogin=true
    }

    return AlreadyLogin;
  }
}
