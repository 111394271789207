<header>
  <div class="upper-bar">
    <div class="container">
      <p class="mb-0 text-white text-right">
        {{data}}م
      </p>

    </div>
  </div>
  <nav class="navbar navbar-expand-lg navbar-light pilgrims-nav">
    <div class="container">

      <a class="navbar-brand mr-4 d-flex align-items-center" style="padding: 0;" [routerLink]="['/home']">
        <img class="img-fluid" src="assets/images/Component.png" style="height: 60px;" alt="Image">
      </a>
      <button class="navbar-toggler mx-1" (click)="showCollapseNav=!showCollapseNav" type="button"
        data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
        aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse " [ngClass]="{'show': showCollapseNav}" id="navbarSupportedContent">
        <ul class="navbar-nav mr-auto d-flex align-items-center
                    font-weight-bold w-100" [ngClass]="{'fix_nav': !isLogin}">
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/home']" routerLinkActive="active">الرئيسية</a>
          </li>
          <!--
                    <li class="nav-item">
                        <a class="nav-link" [routerLink]="['/contracts/list']"
                            routerLinkActive="active">عقود الكترونية</a>
                    </li> -->

          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/inquiries/pilgrims-query']" routerLinkActive="active">الاستعلام</a>
          </li>

          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/companies/list']" routerLinkActive="active">الشركات
              المعتمدة</a>
          </li>

          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/fatwa/list']" routerLinkActive="active">الفتاوى</a>
          </li>

          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/manasek/list']" routerLinkActive="active">المناسك</a>
          </li>

          <!-- <li class="nav-item">
                        <a class="nav-link" [routerLink]="['/lecture/list']" routerLinkActive="active">محاضرات لايف</a>
                    </li> -->
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/news/list']" routerLinkActive="active">اخبار ومقالات</a>
          </li>

          <button *ngIf="isLogin" mat-button class="nav-item dropdown
                        plig-drop" [matMenuTriggerFor]="menu">
            <a class="nav-link dropdown-toggle" dropdownToggle id="navbarDropdown">
              <i class="fas fa-user"></i>
              حسابى
            </a>
          </button>



          <mat-menu #menu="matMenu">
            <button mat-menu-item [routerLink]="['/travels/my-travel']">
              <img src="assets/images/pilgrim-image/my-account/airplane.svg" alt="Image" width="35">
              <span class="font-14 font-weight-bold mx-2">رحلاتى</span>
            </button>

            <div class="dropdown-divider"></div>
            <button mat-menu-item [routerLink]="['/main-data/my-data']">
              <img src="assets/images/pilgrim-image/my-account/resume.svg" alt="Image" width="35">
              <span class="font-14 font-weight-bold mx-2">بياناتى</span>
            </button>
            <div class="dropdown-divider"></div>
            <button mat-menu-item [routerLink]="['/complaints/my-complaints-list']">
              <img src="assets/images/pilgrim-image/my-account/complain.svg" alt="Image" width="35">
              <span class="font-14 font-weight-bold mx-2">الشكاوى</span>
            </button>
            <div class="dropdown-divider"></div>
            <button (click)="logout()" mat-menu-item>
              <img src="assets/images/pilgrim-image/my-account/logout.svg" alt="Image" width="35">
              <span class="font-14 font-weight-bold mx-2">تسجيل
                الخروج</span>
            </button>


          </mat-menu>
          <li class="nav-item mobile-none">
            <a href="tel:19654 (202+)" title="الخط الساخن : 19654"><img style="width: 115px;object-fit:
                                cover;margin-bottom: -14px;" src="assets/images/Hotline.png" alt="19654"></a>
          </li>

        </ul>
      </div>
    </div>
  </nav>

</header>

<ng-content></ng-content>


<footer class="pilgrims-footer">
  <div class="container">
    <p class="text-center">© حقوق النسخ والطبع 2023. جميع الحقوق محفوظة
      للبوابة المصرية للعمرة</p>
  </div>
</footer>