import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Subject } from 'rxjs';

interface LinkObject {
  isActive: boolean;
  page: number;
  slug: string;
}

function isEven(maxLength: number): boolean {
  return maxLength / 2 === 0;
}

@Component({
  selector: 'c-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss'],
})
export class PaginatorComponent implements OnInit, OnChanges, AfterViewInit {
  to: number = 0;
  from: number = 0;
  showPrev: boolean = false;
  showNext: boolean = false;
  hasController: boolean = false;
  links: Array<LinkObject> = [];

  private pagesCount: number = 0;
  private currentPage: number = 0;

  private fromToChange: Subject<void> = new Subject<void>();

  @Input() total: number = 0;
  @Input() perPage: number = 0;
  @Input() maxLength = 7;
  @Input() showController: boolean = true;

  @Output() next: EventEmitter<number> = new EventEmitter<number>();
  hideComponent: boolean = true;

  constructor() {}

  ngAfterViewInit(): void {
    this.fromToChange.subscribe((res) => {
      let { from, to, links } = this.genPages();
      this.links = links;
      this.from = from;
      this.to = to;
      this.showPrev = this.currentPage > 1;
      this.showNext = this.currentPage <= this.pagesCount - this.maxLength + 1;
      this.hideComponent =
        isNaN(this.pagesCount) ||
        isNaN(this.currentPage) ||
        isNaN(from) ||
        isNaN(to) ||
        isNaN(this.total) ||
        isNaN(this.perPage);
    });

    setTimeout(() => {
      this.setCurrentPage(1);
    }, 100);
  }

  ngOnInit(): void {}

  loadPage(link: LinkObject) {
    this.setCurrentPage(link.page);
  }

  private genPages() {
    this.pagesCount = Math.ceil(this.total / this.perPage);
    let to = this.currentPage * this.perPage;
    let from = to - this.perPage + 1;
    let links: Array<LinkObject> = this.makeLinks(this.pagesCount);

    to = to > this.total ? this.total : to;
    return { links, from, to };
  }

  private setCurrentPage(page: number) {
    this.currentPage = page;
    this.next.emit(page);
    this.fromToChange.next();
  }

  private makeLinks(pagesCount: number): Array<LinkObject> {
    if (pagesCount === 0 || isNaN(pagesCount)) return [];
    if (pagesCount <= this.maxLength) {
      this.hasController = false;
      return this.makeLinksList(pagesCount, 1);
    } else {
      this.hasController = true;
      return this.makeLinksList(
        isEven(this.maxLength) ? this.maxLength + 1 : this.maxLength,
        this.getStartPage()
      );
    }
  }

  private makeLinksList(avg: number, start: number): Array<LinkObject> {
    let links: Array<LinkObject> = [];
    for (let i = 0; i < avg; i++) {
      let currentPageNumber = start + i;
      links.push({
        isActive: currentPageNumber === this.currentPage,
        slug: currentPageNumber.toString(),
        page: currentPageNumber,
      });
    }
    return links;
  }

  prevPage() {
    this.setCurrentPage(this.currentPage - 1);
  }

  nextPage() {
    this.setCurrentPage(this.currentPage + 1);
  }

  private getStartPage() {
    if (this.currentPage < this.maxLength) {
      return 1;
    } else if (this.currentPage > this.pagesCount - this.maxLength + 1) {
      return this.pagesCount - this.maxLength + 1;
    } else {
      return this.currentPage - Math.floor(this.maxLength / 2);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.currentPage === 0) this.currentPage = 1;
    this.fromToChange.next();
  }
}
