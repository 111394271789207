import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OneColumnComponent } from './layouts/one-column/one-column.component';
import { RouterModule } from '@angular/router';
import {MatMenuModule} from '@angular/material/menu';

@NgModule({
  declarations: [OneColumnComponent],
  exports:[OneColumnComponent],
  imports: [
    CommonModule,
    MatMenuModule,
    RouterModule
  ]
})
export class themeModule { }
