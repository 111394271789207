<div
  *ngIf="!hideComponent"
  class="d-flex justify-content-between align-items-center mt-2 p-2"
>
  <div>إظهار {{ from }} إلى {{ to }} من {{ total }} سجل</div>
  <nav aria-label="Page navigation example" dir="ltr">
    <ul class="pagination d-flex flex-row-reverse">
      <li
        (click)="prevPage()"
        class="page-item ml-2"
        *ngIf="showPrev && hasController && showController"
      >
        <a class="page-link" aria-label="Previous">
          <span aria-hidden="true" class="p-0">
            <i class="fas fa-chevron-right"></i>
          </span>
          <span class="sr-only">Previous</span>
        </a>
      </li>
      <li
        class="page-item"
        [class.active]="link.isActive"
        (click)="loadPage(link)"
        *ngFor="let link of links"
      >
        <a class="page-link">{{ link.slug }}</a>
      </li>
      <li
        (click)="nextPage()"
        class="page-item mr-2"
        *ngIf="showNext && hasController && showController"
      >
        <a class="page-link pointer" aria-label="Next">
          <span aria-hidden="true" class="p-0">
            <i class="fas fa-chevron-left"></i>
          </span>
          <span class="sr-only">Next</span>
        </a>
      </li>
    </ul>
  </nav>
</div>
